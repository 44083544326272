import Router from 'next/router'
import { Constants } from 'common/constants'
import { Project } from 'common/project'
import { ApiTypes } from 'common/api/types/api-types'
import { setApi } from 'common/api'
import storage from './async-storage-api'
import Strings from './localisation'
import { GAEvent, GAEventData, GAEventType } from 'types/global'
function gtag(eventType: GAEventType, event?: GAEvent, data?: GAEventData) {
  if (eventType === 'page_view') {
    const payload = {
      event: 'Pageview',
      pagePath: document.location.href,
      pageTitle: document.title,
      visitorType: 'customer',
    }
    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  } else {
    const payload = {
      ecommerce_ga4: data,
      event,
    }

    API.log('ANALYTICS', payload)
    dataLayer.push(payload)
  }
}
interface WebAPI
  extends ApiTypes<
    any,
    {
      removeItem: (key: string, req?: any) => undefined | unknown
      getItemSync: (key: string, req?: any) => string
      setItemSync: (
        key: string,
        value: string,
        req?: any,
      ) => undefined | unknown
      removeItemSync: (key: string, req?: any) => undefined | unknown
    }
  > {
  getStoredLocale: (requestedLocale?: string) => string
  setStoredLocale: (requestedLocale: string) => void
  logoutRedirect: () => void
}

const API: WebAPI = {
  confirmEmailRedirect(email) {
    Router.replace(`/auth/confirm-email?email=${email}`)
  },
  getAPIBaseUrl: () => process.env.NEXT_PUBLIC_API_URL || '',
  getStoredLocale: (requestedLocale?: string) => {
    return (
      API.storage.getItemSync('NEXT_LOCALE') ||
      requestedLocale ||
      Constants.defaultLocale
    )
  },
  identify(id) {
    // @ts-ignore
    if (Project.mixpanel && typeof mixpanel !== 'undefined') {
      // @ts-ignore
      mixpanel.identify(id)
    }
  },

  isMobile: () => false,
  // @ts-ignore
  log(namespace: keyof (typeof Project)['logs'], ...args: any[]) {
    if (Project.logs[namespace]) {
      // eslint-disable-next-line no-console
      console.log.apply(this, [namespace, ...args])
    }
  },

  loginRedirect(user, redirect) {
    const params = Router.query
    // const profile:AppState['profile'] = _store().getState().profile;
    params.redirect = params.redirect || redirect || '/account'
    // @ts-ignore
    Router.replace(params.redirect, params.as || params.redirect, {
      shallow: true,
    })
  },

  logoutRedirect() {
    const redirect = encodeURIComponent(Router.route)
    const as = encodeURIComponent(Router.asPath)
    let path = `/?redirect=${redirect}`
    if (redirect !== as) {
      path += `&as=${as}`
    }
    Router.replace(path)
  },

  middlewares: [],

  setStoredLocale: (locale: string) => {
    Strings.setLanguage(locale)
    API.storage.setItemSync('NEXT_LOCALE', locale)
    // document.location = document.location
    // @ts-ignore
    const { asPath, pathname, query } = Router.router?.state || {}
    // change just the locale and maintain all other route information including href's query
    // @ts-ignore
    Router.router?.push({ pathname, query }, asPath, { locale })
  },

  storage,

  trackEvent(event: GAEvent, data: GAEventData) {
    if (typeof dataLayer !== 'undefined') {
      gtag('event', event, data)
    }
  },

  trackPage() {
    if (typeof dataLayer !== 'undefined') {
      gtag('page_view')
    }
  },
}
setApi(API)
export { API }
