import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { API } from 'project/api'
import gtm from 'react-gtm-module'
import { Project } from 'common/project'
import { NextPageWithLayout } from 'types/nextPageWithLayout'

type ComponentType = {
  Component: NextPageWithLayout
}

const AnalyticsContainer: FC<ComponentType> = ({ Component }) => {
  const router = useRouter()
  const trackPage = () => {
    API.trackPage()
  }
  const handleRouteChange = async () => {
    const properties = await (Component.getAnalyticsProperties
      ? Component.getAnalyticsProperties()
      : Promise.resolve({}))
    trackPage()
  }
  useEffect(() => {
    if (typeof window !== 'undefined' && Project.gtm) {
      // @ts-ignore
      gtm.initialize({ gtmId: Project.gtm })
      trackPage() // track initial page
    }
  }, [])
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return <></>
}

export default AnalyticsContainer
