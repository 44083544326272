export const Project = {
  api: 'https://api.hoxtonmix.com/api/v3/',
  auth: {
    cognito: true,
  },
  //https://checkout.hoxtonmix.com
  chargbeeSite: 'hoxtonmix',

  chargebeeUrl: 'https://hoxtonmix.chargebee.com',
  cognito: {
    oauth: {
      domain: 'ssg-boilerplate.auth.eu-west-2.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'http://localhost:3000',

      redirectSignOut: 'http://localhost:3000',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'eu-west',
    userPoolId: 'eu-west-2_uvQbrAUmt',
    userPoolWebClientId: 'gvjqjukmm1e2b1mrhpebeumte',
  },

  cognitoMobile: {
    oauth: {
      domain: 'ssg-boilerplate.auth.eu-west-2.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'ssgfeb://',

      redirectSignOut: 'ssgfeb://',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'eu-west',
    userPoolId: 'eu-west-2_uvQbrAUmt',
    userPoolWebClientId: 'gvjqjukmm1e2b1mrhpebeumte',
  },
  dataRelay: '1637-c53c-21bc',

  //https://checkout.hoxtonmix.com
  debug: false,

  flags: {
    go_cardless: true,
    market_place: true,
    related_offers: true,
  },

  gtm: 'GTM-K636ZW',
  imageProxy: 'https://imgproxy.hoxtonmix.com/',
  logs: {
    ANALYTICS: false,
    API: false,
    STORAGE: false,
  },
  loqate: 'PR28-BF96-PB24-GY46',
  paypal:
    'AYDir50vh5xNBX8eySN3lC2l7SAdrj4uLL-XlmlVba8HJEl6m2WzZE8F6fHhfZw-Nt9ca7Pskoz2pVWO',
  portalURL: 'https://account.hoxtonmix.com/',
  sentry:
    'https://36ba2215354e4e0e90e43595574de811@o31090.ingest.sentry.io/4504441225543680',
  sentryEnvironment: 'production',
  stripe: 'pk_live_ytJiDoNyHkhxj253hJTpAqJl',
  testCafeStage: 'production',
}
